import Script from "next/script";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { GA_ID_TBZ, Google_Ads_ID_TBZ, GTM_ID } from "@modules/lib/gtm";
export default function HeaderScript() {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = () => {
      window.fbq("track", "PageView");
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  return <>
      <Script id="googleTagManager" strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: ` 
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');
          `
    }} />
      {/* tbz google */}
      <Script id="dataLayer3" strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID_TBZ}`} />
      <Script id="dataLayer4" strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_ID_TBZ}');
          gtag('config', '${Google_Ads_ID_TBZ}');
          `
    }} />
      {/* facebook */}
      <Script id="facebook" strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: ` 
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '2079685385672750');
          fbq('track', 'PageView');
        `
    }} />
      {/* kakao */}
      <Script id="kakao1" strategy="beforeInteractive" type="text/javascript" charSet="UTF-8" src="//t1.daumcdn.net/kas/static/kp.js" />
      <Script id="kakao2" strategy="lazyOnload" dangerouslySetInnerHTML={{
      __html: `kakaoPixel('879368359230355491').pageView();`
    }} />
      {/* criteo */}
      <Script id="criteo" type="text/javascript" src="//dynamic.criteo.com/js/ld/ld.js?a=109493" async={true} strategy="afterInteractive" />
      {/*<span itemScope itemType="http://schema.org/Organization">*/}
      {/*  <link itemProp="url" href="https://www.tripbtoz.com" />*/}
      {/*  <a*/}
      {/*    itemProp="sameAs"*/}
      {/*    href="https://apps.apple.com/kr/app/%ED%8A%B8%EB%A6%BD%EB%B9%84%ED%86%A0%EC%A6%88-play-share-stay/id1206972376"*/}
      {/*  ></a>*/}
      {/*  <a*/}
      {/*    itemProp="sameAs"*/}
      {/*    href="https://play.google.com/store/apps/details?id=com.tripbtoz.androidgotivia"*/}
      {/*  ></a>*/}
      {/*  <a itemProp="sameAs" href="https://blog.naver.com/tripbtoz"></a>*/}
      {/*  <a itemProp="sameAs" href="https://www.instagram.com/tripbtoz/?hl=ko"></a>*/}
      {/*  <a itemProp="sameAs" href="https://www.youtube.com/@tripbtoz3441"></a>*/}
      {/*</span>*/}

      {/*tiktok*/}
      {/*<Script*/}
      {/*  id="tiktok"*/}
      {/*  strategy="afterInteractive"*/}
      {/*  dangerouslySetInnerHTML={{*/}
      {/*    __html: `!function (w, d, t) { */}
      {/*    w.TiktokAnalyticsObject=t;*/}
      {/*    var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};*/}
      {/*    for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);*/}
      {/*    ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";*/}
      {/*    ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};*/}
      {/*    n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};*/}
      {/*    ttq.load('CNT4RH3C77U5M8RPI6E0');*/}
      {/*    ttq.page();*/}
      {/*    }(window, document, 'ttq');`,*/}
      {/*  }}*/}
      {/*/>*/}
    </>;
}