import "react-datepicker/dist/react-datepicker.css";
import "@styles/globals.css";
import "@styles/datepicker-custom.css";
import "@styles/ckeditor5-editor.css";
import { appWithTranslation } from "next-i18next";
import TbzMetaHead from "../components/meta/TbzMetaHead";
import TTBBMetaHead from "../components/meta/TTBBMetaHead";
import HeaderScript from "../components/layout/HeaderScript";
import HeaderTTBBScript from "@components/layout/HeaderTTBBScript";
import { useDomain } from "@modules/hooks/useDomain";
import { datadogRum } from "@datadog/browser-rum";
import MetaSourceProvider from "@components/meta/MetaSourceProvider";
datadogRum.init({
  applicationId: "f35181e4-d856-4137-b2d6-b803872a0946",
  clientToken: "pub2327d7555f3c71c22bc4129a7542203d",
  site: "datadoghq.com",
  service: "tripbtoz-www",
  env: process.env.NEXT_PUBLIC_DEV_ENV_INIT,
  version: process.env.NEXT_PUBLIC_PACKAGE_VERSION,
  sessionSampleRate: 10,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [/^https:\/\/(dev-|stage-)?api\.tripbtoz\.com/, /^https:\/\/(dev-|stage-)?push-api\.tripbtoz\.com/, /^https:\/\/(dev-|stage-)?payments\.tripbtoz\.com/, /^https:\/\/(dev-|stage-)?www\.tripbtoz\.com\/api/, /https:\/\/.*\.triptoz\.com/]
});
function App({
  Component,
  pageProps
}) {
  const isTTBB = useDomain();
  if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (search, replacement) {
      return this.split(search).join(replacement);
    };
  }
  const siteMeta = isTTBB ? <TTBBMetaHead pageProps={pageProps} /> : <TbzMetaHead pageProps={pageProps} />;
  const siteScript = process.env.NEXT_PUBLIC_DEV_ENV !== "development" ? <>{isTTBB ? <HeaderTTBBScript /> : <HeaderScript />}</> : <></>;
  return <>
      {siteMeta}
      {siteScript}
      <MetaSourceProvider>
        <Component {...pageProps} />
      </MetaSourceProvider>
    </>;
}
export default appWithTranslation(App);