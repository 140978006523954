import { useState } from "react";

/**
 * Parameter
 * key : sessionStorage key 값
 * */

const useSessionStorage = (key, initialValue = {})=>{
  const [ storageValue, setStorageValue] = useState(
    ()=>{
      if(typeof window === 'undefined') return initialValue
      try{
        const value = sessionStorage.getItem(key)
        return value ? JSON.parse(value) : initialValue
      } catch(e) {
        console.warn('localStorage 에 해당 값이 존재하지않거나, 읽어오는 과정에서 에러가 발생했습니다.', e)
        return initialValue
      }
    }
  )

  const setSessionStorageValue = (value) => {
    if(typeof window === 'undefined') return console.warn('localStorage 를 사용할 수 없는 환경입니다.')

    try {
      const newValue = value instanceof Function ? value(storageValue) : value

      sessionStorage.setItem(key, JSON.stringify(newValue))

      setStorageValue(newValue)

    } catch(e) {
      console.warn('localStorage 에 값을 저장하는데 실패햐였습니다.', `${key} : ${e}`)
    }
  }


  return [storageValue , setSessionStorageValue]
}

export default useSessionStorage