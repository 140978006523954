import { createContext, useEffect } from "react";
import { useRouter } from "next/router";
import { add, isWithinInterval } from "date-fns";
import dayjs from "dayjs";
import MakeHeaders from "@modules/utils/makeHeaders";
import useLocalStorage from "@modules/hooks/useLocalStorage";
import useSessionStorage from "@modules/hooks/useSessionStorage";
import { ConvertLocale } from "@modules/utils/ConvertLocale";

/**
 * meta api response 규격
 *  {
 *     "name": NAVER,
 *     "booking_window": {
 *         "amount": 30, //유지 기간 ( amount= 0 일 경우, session 유지)
 *         "enabled_meta": true, // META 어부
 *         "enabled_settlement": true, //정산 여부
 *         "start_date": "2024-08-06T04:27:47.787Z",
 *         "expiration_date": "2024-09-05T04:27:47.787Z"
 *     },
 *     "annexed_info": {
 *         "source_type": "1", //isnaver
 *         "source_id": "9h3knNPE9rxFQCLNmN%24RxQ", //트랜잭션 아이디
 *         "source_account": "dsklgjsd89fg7kds", // naver_label
 *         "extern_hotel_id": "35487", // 호컴 호텔아이디
 *         "fixed_hotel_id": null // 트립비토즈 호텔 아이디 (특정 호텔에만 적용해야할때)
 *     }
 * }
 * **/

/**
 * meta localStorage 규격
 * {
 *   ...meta_api_response,
 *    url : current_url,
 *    created_date: current_date
 *    condition : {
 *      hotel? : boolean // srookpay 진입시 호텔과 동일 체크
 *    }
 * }
 * **/

export const META = {
  TBZ_CRM: "TBZ_CRM",
  TBZ_DIRECT: "TBZ_DIRECT",
  NAVER: "NAVER"
};
export const TBZ_CRM_META = {
  name: META.TBZ_CRM,
  booking_window: {
    amount: 365,
    enabled_meta: false,
    enabled_settlement: false,
    start_date: new Date(),
    expiration_date: add(new Date(), {
      days: 365
    })
  },
  annexed_info: {},
  created_date: new Date(),
  condition: {}
};
export const NAVER_EXT_OPTS = {
  N1: "N1",
  N0: "N0"
};
export const MetaSourceContext = createContext({
  meta: {},
  setMeta: () => {}
});
const MetaSourceProvider = ({
  children
}) => {
  const router = useRouter();
  const {
    referrer_url
  } = router.query;
  const [meta, setMeta] = useSessionStorage("meta_source", {
    name: ""
  });
  const [localMeta, setLocalMeta] = useLocalStorage("meta_source", {
    name: ""
  });
  const handleCheckValidMeta = info => {
    let result = true;
    if (!info.name) {
      if (localMeta.name) {
        result = isWithinInterval(new Date(), {
          start: new Date(localMeta.booking_window.start_date),
          end: new Date(localMeta.booking_window.expiration_date)
        });
      } else result = false;
    }
    return result;
  };
  const handleResetMeta = storage => {
    setMeta(storage?.session || {
      name: ""
    });
    setLocalMeta(storage?.local || {
      name: ""
    });
  };
  const handleRemoveMeta = () => {
    localStorage.removeItem("meta_source");
    sessionStorage.removeItem("meta_source");
  };
  const handleSetMeta = newMeta => {
    if (newMeta.booking_window?.amount === 0) {
      localStorage.removeItem("meta_source");
    } else setLocalMeta(newMeta);
    setMeta(newMeta);
  };
  const handleMetaStorage = data => {
    //유효하지않은 메타
    if (!data.name) return;
    const valid = handleCheckValidMeta(meta);
    if (!valid) handleRemoveMeta();
    const {
      name: prev_meta_name,
      annexed_info: prev_annexed_info,
      condition
    } = valid ? meta.name ? meta : localMeta : {};
    const newMeta = {
      ...data,
      url: document.location.href,
      created_date: new Date(),
      condition: {}
    };

    //동일 호텔 체크

    if (!!newMeta.annexed_info?.fixed_hotel_id) newMeta.condition.hotel = true;

    //기존 메타가 없거나, 만료된 메타
    if (!prev_meta_name) handleSetMeta(newMeta);
    if (prev_meta_name) {
      if (newMeta.name === META.TBZ_DIRECT) {
        if (condition.hasOwnProperty("hotel") && newMeta.url.includes("/hotels")) handleSetMeta({
          ...meta,
          condition: {
            ...meta.condition,
            hotel: newMeta.url.includes(String(prev_annexed_info?.fixed_hotel_id))
          }
        });
        if (!meta.name && localMeta.name) handleSetMeta(localMeta);
        return;
      }
      if (newMeta.booking_window.amount) {
        if (!meta.name && localMeta.name && prev_meta_name === newMeta.name) handleSetMeta({
          ...newMeta,
          created_date: localMeta.created_date
        });else handleSetMeta(newMeta);
      }
      if (!newMeta.booking_window.amount) handleSetMeta(newMeta);
    }

    //locale 영문 일 때
    if (router.locale === "en-US") {
      if (prev_meta_name === META.NAVER || newMeta.name === META.NAVER) handleSetMeta({});
    }
  };
  useEffect(() => {
    if (meta && meta.url === document.location.href && dayjs(new Date()).isSame(meta.created_date, "day")) return;
    fetch(`/api/meta`, {
      method: "POST",
      body: JSON.stringify({
        referer_url: referrer_url || document.referrer,
        request_url: document.location.href
      }),
      headers: MakeHeaders({
        "Accept-Language": ConvertLocale(router.locale)
      })
    }).then(async response => {
      const data = await response.json();
      handleMetaStorage(data);
    });
  }, [router.query]);
  return <MetaSourceContext.Provider value={{
    meta,
    setMeta,
    handleResetMeta
  }}>
      {children}
    </MetaSourceContext.Provider>;
};
export default MetaSourceProvider;