import Head from "next/head";
import { DefaultSeo, SocialProfileJsonLd } from "next-seo";
function TbzMetaHead({
  pageProps
}) {
  return <>
      <DefaultSeo title={pageProps.locale === "ko-KR" ? "트립비토즈 | 차세대 여행 플랫폼 | 전 세계 87만개 숙소 예약 | 영상 리뷰" : "Tripbtoz"} description={pageProps.locale === "ko-KR" ? "전 세계 87만 개 숙소 예약, 앱 전용 특별 할인까지 호텔 예약은 트립비토즈" : "870,000 accommodation reservations worldwide, Tripbtoz makes booking easy"} openGraph={{
      type: "website",
      url: "https://www.tripbtoz.com/",
      locale: pageProps.locale,
      title: `${pageProps.locale === "ko-KR" ? "트립비토즈" : "Tripbtoz"}`,
      description: `${pageProps.locale === "ko-KR" ? "전 세계 87만 개 숙소 예약, 앱 전용 특별 할인까지 호텔 예약은 트립비토즈" : "870,000 accommodation reservations worldwide, Tripbtoz makes booking easy"}`,
      images: [{
        url: "https://qeuxmtqauiit1072966.cdn.ntruss.com/meta/tripbtoz/opengraph_1200_627_202204.png"
      }]
    }} twitter={{
      cardType: "summary",
      site: "@Tripbtoz"
    }} />
      <SocialProfileJsonLd type="Organization" name="트립비토즈 : 전 세계 87만 개 숙소 예약" url="https://www.tripbtoz.com/" sameAs={["https://apps.apple.com/kr/app/%ED%8A%B8%EB%A6%BD%EB%B9%84%ED%86%A0%EC%A6%88-play-share-stay/id1206972376", "https://play.google.com/store/apps/details?id=com.tripbtoz.androidgotivia", "https://blog.naver.com/tripbtoz", "https://www.instagram.com/tripbtoz/?hl=ko", "https://www.youtube.com/@tripbtoz3441"]} />
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="keywords" content="트립비토즈, 호텔, 리조트, 숙박, 여행, 해외여행, 국내여행, 여행기록, 여행영상, 챌린지, 랭킹, 트립톡, 메타버스, 메타버스여행, 호텔할인, 호텔예약, 주말여행, 추천호텔, 특가호텔, 호캉스,
          트립비토즈 리조트, 트립비토즈 숙박, 트립비토즈 여행, 트립비토즈 해외여행, 트립비토즈 국내여행, 트립비토즈 여행기록, 트립비토즈 여행영상, 트립비토즈 챌린지, 트립비토즈 랭킹" />
        <meta name="apple-mobile-web-app-capable" content="no" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        {/*<script*/}
        {/*  type="application/ld+json"*/}
        {/*  dangerouslySetInnerHTML={{*/}
        {/*    __html: JSON.stringify({*/}
        {/*      "@context": "http://schema.org",*/}
        {/*      "@type": "Organization",*/}
        {/*      name: "트립비토즈 : 전 세계 87만 개 숙소 예약",*/}
        {/*      url: "https://www.tripbtoz.com/",*/}
        {/*      sameAs: [*/}
        {/*        "https://apps.apple.com/kr/app/%ED%8A%B8%EB%A6%BD%EB%B9%84%ED%86%A0%EC%A6%88-play-share-stay/id1206972376",*/}
        {/*        "https://play.google.com/store/apps/details?id=com.tripbtoz.androidgotivia",*/}
        {/*        "https://blog.naver.com/tripbtoz",*/}
        {/*        "https://www.instagram.com/tripbtoz/?hl=ko",*/}
        {/*        "https://www.youtube.com/@tripbtoz3441",*/}
        {/*      ],*/}
        {/*    }),*/}
        {/*  }}*/}
        {/*/>*/}
        <link rel="apple-touch-startup-image" href="/weblogo/tbz/apple-splash/apple-splash-640-1136.jpg" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/tbz/apple-splash/apple-splash-750-1334.jpg" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"></link>
        <link rel="apple-touch-startup-image" href="/weblogo/tbz/apple-splash/apple-splash-1242-2208.jpg" media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"></link>
        <link rel="icon" type="image/svg+xml" href="/weblogo/tbz/favicon/favicon.svg" sizes="any"></link>
        <link rel="icon" type="image/png" href="/weblogo/tbz/favicon/favicon-16x16.png" sizes="16x16"></link>
        <link rel="icon" type="image/png" href="/weblogo/tbz/favicon/favicon-32x32.png" sizes="32x32"></link>
      </Head>
    </>;
}
export default TbzMetaHead;